import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Anir Bouzit </span>
            from <span className="purple"> Marrakech, Morocco.</span>
            <br />
            I am currently a student at OFPPT NTIC SYBA MARRAKECH.
            <br />
            {/* I have completed Integrated MSc (IMSc) in Maths and Computing at BIT
            Mesra.
            <br /> */}
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> 3D modeling
            </li>
            <li className="about-activity">
              <ImPointRight /> Video editing
            </li>
            <li className="about-activity">
              <ImPointRight /> Embedded systems
            </li>
            <li className="about-activity">
              <ImPointRight /> Game development
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">Anir</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
